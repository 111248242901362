.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.user__edit-profile__modal__sub-element {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: white;
  border: 1px solid #3e79f7;
  border-radius: 10px;
  margin-bottom: 10px;
}
.user__edit-profile__modal__sub-element.selected,
.user__edit-profile__modal__sub-element:hover {
  background-color: #3e79f7;
  color: white;
}
.user__edit-profile__modal__sub-element:last-of-type {
  margin-bottom: unset;
}
.user__edit-profile__status-box {
  font-size: 16px;
  margin-left: 20px;
}
@media (max-width: 768px) {
  .user__edit-profile__status-box {
    margin-left: 0;
    margin-top: 20px;
  }
}
.user-blocked {
  background-color: #f5f5f5;
}
.loading .ant-spin {
  color: #3e79f7;
}
